
import { useFacetsStore } from '#imports'
import { Component, Vue } from '~/utility/pu-class-decorator'
import SCHEMA_ORGANIZATION from '../json/schemaOrganizationWithAggregateRating.json'
import { langFallback$Fetch } from '../lang/utils/langfetch'

export type PitchupStats = {
  campsiteReviewsCount: number
  campsitePhotosCount: number
  campsitesBookableCount: number
  campsitesActiveCount: number
}

const POPULAR_FEATURES_SLUGS = [
  'electric',
  'beaches',
  'hot-tub',
  'campfires-allowed',
  'bar-or-club-house',
  'adults-only',
  'pitchtype-fully-serviced',
  'wild-camping',
  'dogs-allowed',
  'outdoor-swimming-pool',
  'pub-campsite',
  'play-area',
  'camping-pods',
  'open-all-year',
]

const _stats = {}

@Component<Homepage>({
  layout: 'homepage-layout',
  httpHeaders: ({ $config }) => {
    return {
      'Cache-Control': `max-age=${$config.nonPriceCacheTime}, public`,
      Grace: `${$config.nonPriceGraceTime}`,
    }
  },
  middleware: ['httpHeaders'],
  async asyncData({ route }) {
    const langCode = route.params.lang || 'en-gb'
    if (_stats[langCode]) return _stats[langCode]
    const [pitchupStats] = await Promise.all([
      langFallback$Fetch<PitchupStats>(langCode, '/_/endpoints/pitchup-stats/'),
    ])
    _stats[langCode] = pitchupStats
    return {
      pitchupStats,
    }
  },
  head: function () {
    return this.head()
  },
  setup() {
    const facetsStore = useFacetsStore()
    return {
      facetsStore,
    }
  },
})
export default class Homepage extends Vue {
  facetsStore: ReturnType<typeof useFacetsStore>

  get popularFeaturesData() {
    return POPULAR_FEATURES_SLUGS.map((slug) =>
      this.facetsStore.facetsBySlug(slug),
    )
      .filter((facet) => facet)
      .map((facet) => ({
        name: facet.name,
        to: {
          facet: facet.slug,
          category: facet.slug === 'hot-tub' ? 'glamping' : 'campsites',
        },
        imgUrl: require(`@/assets/homepage/features/${facet.slug}.jpg`),
      }))
  }

  pitchupStats: PitchupStats | null = null

  head() {
    return {
      title:
        (this.$i18n.t(
          'Camping, Glamping, Caravan and Holiday Parks',
        ) as string) + ' | Pitchup.com',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$i18n.t(
            'Camping, Glamping, Caravan and Holiday Parks',
          ) as string,
        },
        {
          hid: 'description',
          name: 'description',
          property: 'og:description',
          content: this.$i18n.t(
            'Book campsites, caravan sites and glamping from the specialists at Pitchup.com, with over {campsitesActiveCount, number, integer} listings and {reviewsCount, number, integer} independent reviews your next holiday awaits you.',
            {
              campsitesActiveCount:
                this.pitchupStats?.campsitesActiveCount || 14000,
              reviewsCount: this.pitchupStats?.campsiteReviewsCount || 730000,
            },
          ) as string,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `https://${this.$config.public.envUrl}${this.$route.fullPath}`,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: `https://${this.$config.public.envUrl}${this.$route.path}app-icons/pitchup_logo_large.png`,
        },
      ],
      bodyAttrs: {
        class: this.$isDesktop ? 'homepage desktop' : 'homepage',
      },
      __dangerouslyDisableSanitizers: ['script', 'innerHTML'],
      script: [
        {
          innerHTML: this.$isDesktop
            ? ''
            : `
            document.getElementById('autosuggest__input')?.addEventListener('click', () => {
              document
                .getElementById('__nuxt')
                .classList.add('homepage-search-open', 'active-tab-search')
            })
          `,
          body: true,
          type: 'text/javascript',
          charset: 'utf-8',
        },
        {
          type: 'application/ld+json',
          body: true,
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: 'Pitchup',
            url: 'https://www.pitchup.com/',
          },
        },
        {
          type: 'application/ld+json',
          body: true,
          json: SCHEMA_ORGANIZATION,
        },
      ],
    }
  }

  deactivated() {
    this.removeClass()
  }

  destroyed() {
    this.removeClass()
  }

  removeClass() {
    this.$nextTick(() => {
      document
        .getElementById('__nuxt')
        ?.classList.remove('homepage-search-open', 'active-tab-search')
    })
  }
}
